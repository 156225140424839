export class Polyfills {
  static init(scope) {
    this.win = scope || window;
    this.dom = this.win.document;
    this.CustomEvents();
    this.Matches();
  }

  static CustomEvents() {
    if ( typeof this.win.CustomEvent === "function" ) return false;

    let dom = this.dom;

    function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = dom.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
     }

    CustomEvent.prototype = this.win.Event.prototype;

    this.win.CustomEvent = CustomEvent;
  }

  // Polyfill from https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
  static Matches() {
    // prevent overriding browser implementation
    if (Element.prototype.matches) return false;
    Element.prototype.matches = Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }
}
