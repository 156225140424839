/** @module @yext/components-run-if-visible */

/**
 * @typedef {Object} IntersectionObserverOptions
 * @property {HTMLElement} [root] The element that is used as the viewport for checking
 *   visibility of the target
 * @property {string} [rootMargin] Margin around the root. Can have values similar to the
 *   CSS margin property, e.g. "10px 20px 30px 40px"
 * @property {number|number[]} [threshold] Either a single number or an array of numbers
 *   which indicate at what percentage of the target's visibility the observer's callback should be executed.
 */

/**
 * Use an IntersectionObserver to run code only when specific elements are within view. This class
 * has a function to run a generic callback, {@link module:@yext/components-run-if-visible.RunIfVisible.runIfTargetVisible runIfTargetVisible},
 * as well as a function to lazy-load images when visible, {@link module:@yext/components-run-if-visible.RunIfVisible.lazyLoadImages lazyLoadImages}.
 */
export class RunIfVisible {
  /**
   * @param {HTMLElement} target DOM Element to observe
   * @param {function} thingToRun Function to execute when target is visible
   * @param {module:@yext/components-run-if-visible~IntersectionObserverOptions} [options={}] The
   *   options to pass to the IntersectionObserver as defined by {@link https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API}
   */
  static runIfTargetVisible(target, thingToRun, options = {}) {
    if (!target) {
      return;
    }

    if (!('IntersectionObserver' in window)) {
      thingToRun();
      return;
    }

    const ObserveOnce = (obs, entries) => {
      entries.forEach((entry) => {
        if(!entry.isIntersecting) return;
        obs.disconnect();
        thingToRun();
      });
    }

    const observer = new IntersectionObserver((entries) => {
      ObserveOnce(observer, entries);
    }, options);

    observer.observe(target);
  }

  /**
   * Lazy-load img elements with data-src or data-srcset, and lazy-load backgrounds on elements with
   * data-bg.
   * @param {string} [selector=''] Selector for all elements to lazy-load
   * @param {module:@yext/components-run-if-visible~IntersectionObserverOptions} [options={}] The
   *   options to pass to the IntersectionObserver as defined by {@link https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API}
   */
  static lazyLoadImages(selector = "", options = {}) {
    for (let el of document.querySelectorAll(`${selector}[data-src]`)) {
      this.runIfTargetVisible(el, () => {
        el.src = el.dataset.src;
        el.classList.add('js-lazy-loaded');
      }, options);
    }
    for (let el of document.querySelectorAll(`${selector}[data-srcset]`)) {
      this.runIfTargetVisible(el, () => {
        el.srcset = el.dataset.srcset;
        el.classList.add('js-lazy-loaded');
      }, options);
    }
    for (let el of document.querySelectorAll(`${selector}[data-bg]`)) {
      this.runIfTargetVisible(el, () => {
        el.style.backgroundImage = el.dataset.bg;
        el.classList.add('js-lazy-loaded');
      }, options);
    }
  }
}
