import { MonitoringInit } from 'js/components/Monitoring/Monitoring.js';
import { Polyfills } from 'js/components/Polyfills/Polyfills.js';
import { Instance } from 'js/components/YextAnalytics/Analytics.js';
import { AccessibilityChecks } from 'js/components/Util/Accessibility.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { ImageObjectFit } from 'js/components/Image/index.js';
import { Header } from 'js/common/modules/Header.js';
import { Debug } from "js/components/Util/Debug.js";
import { Instance as WCAGNewTab } from "js/components/Util/WcagNewTab.js";
import { AnalyticsDebugger } from "ts/components/AnalyticsDebugger/AnalyticsDebugger.ts";
import { RunIfVisible } from "js/components/RunIfVisible";

import 'script-loader!node_modules/svg4everybody/dist/svg4everybody.min.js';

export class Global {
  static init() {
    Polyfills.init();
    MonitoringInit();

    OnReady(() => {
      RunIfVisible.lazyLoadImages();
      new Header();

      Yext.Analytics = Instance;
      const thisYear = new Date().getFullYear();

      const dateSpans = document.querySelectorAll('.c-copy-date');
      for (const dateSpan of dateSpans) {
        dateSpan.innerHTML = `&copy; ${thisYear}`;
      }
      ImageObjectFit();

      svg4everybody();
      WCAGNewTab.wcagify();

      if (Yext.isStaging ||
          window.location.href.includes('yextpages') ||
          window.location.href.includes('localhost')) {
        AccessibilityChecks.checkAltTags();
      }

      if (Debug.isEnabled()) {
        window.Debugger = new AnalyticsDebugger();
      }

      const toTop = document.querySelectorAll('.js-to-top');

      if (toTop) {
        toTop.forEach((element) => {
          element.addEventListener('click', () => {
            window.scroll({top: 0, behavior: "smooth"});
          });
        });
      }

    });
  }
}
