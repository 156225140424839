import { AccessibilityHelpers } from 'js/components/Util/Accessibility.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
const createFocusTrap = require('focus-trap/index.js');

let accessibilityHelpersInstance = new AccessibilityHelpers();

export class Header {
  constructor(mobileBP = 991) {
    this.headerEl = document.getElementById('Header');
    this.menuOverlayEl = this.headerEl.querySelector('.Header-overlay');
    this.navEl = this.headerEl.querySelector('.Header-content');
    this.menuEl = this.headerEl.querySelector('.Header-menu');
    this.menuBtnEl = this.headerEl.querySelector('.Header-toggleIcon');
    this.menuItems = this.headerEl.querySelectorAll('.Header-menuItem a');
    this.hasBeenSetupMobile = false;
    this.mobileBP = window.matchMedia(`(max-width: ${mobileBP}px)`);
    this.wasDesktop = !this.mobileBP.matches;
    this.focusTrap = createFocusTrap('#Header', {
      onActivate: () => {
        this.handleHeaderTabIndexes(0);
      },
      onDeactivate: () => {
        this.handleHeaderTabIndexes(-1);
      },
    });

    this.handleMobileHeader();
    this.mobileBP.addListener(this.handleMobileHeader.bind(this));
  }

  handleMobileHeader() {
    if (this.mobileBP.matches) {
      this.enableMobileHeader();
    } else {
      this.resetMobileHeader();
    }
  }

  enableMobileHeader() {
    const headerLogic = () => {
      this.menuOverlayEl.style['display'] = 'block';

      if (this.headerEl.classList.toggle('isOpen')) {
        this.navEl.style['height'] = `${this.menuEl.offsetHeight}px`;
        this.menuOverlayEl.style['height'] = `${this.menuEl.offsetHeight}px`;
        this.menuOverlayEl.style['width'] = `200%`;
        this.menuOverlayEl.style['opacity'] = '.7';
        this.navEl.style.transform= "translateX(280px)";
        this.menuOverlayEl.style.transform= "translateX(280px)";
        this.focusTrap.activate();
      } else {
        this.navEl.style['height'] = '0px';
        this.menuOverlayEl.style['opacity'] = '0';
        this.navEl.style.transform= "translateX(-280px)";
        this.menuOverlayEl.style.transform= "translateX(-280px)";
        window.setTimeout(() => {
          this.menuOverlayEl.style['display'] = 'none';
        }, 300); // matches opacity transition time
        this.focusTrap.deactivate();
      }

      accessibilityHelpersInstance.toggleAriaState(this.navEl, 'hidden');
      accessibilityHelpersInstance.toggleAriaState(this.menuBtnEl, 'expanded');
      accessibilityHelpersInstance.toggleAriaState(this.menuOverlayEl, 'expanded');
      accessibilityHelpersInstance.toggleAriaState(this.menuOverlayEl, 'hidden');
    };

    this.handleHeaderTabIndexes(-1);
    accessibilityHelpersInstance.setAriaProp(this.navEl, 'hidden', true);

    if (!this.hasBeenSetupMobile) {
      this.menuBtnEl.addEventListener('click', headerLogic);
      this.menuOverlayEl.addEventListener('click', headerLogic);
      this.hasBeenSetupMobile = true;
    }
    this.wasDesktop = false;
  }

  resetMobileHeader() {
    if (!this.wasDesktop) {
      this.headerEl.classList.remove('isOpen');
      this.navEl.style = '';
      accessibilityHelpersInstance.setAriaProp(this.navEl, 'hidden', false);
      this.focusTrap.deactivate();
      this.handleHeaderTabIndexes(0);
      this.wasDesktop = true;
    }
  }

  handleHeaderTabIndexes(tabIndex) {
    accessibilityHelpersInstance.setTabIndex(this.menuItems, tabIndex);
    accessibilityHelpersInstance.setTabIndex(this.menuOverlayEl, tabIndex);
  }
}
