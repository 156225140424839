/* ABOUT THIS FILE
 * The analytics component has been moved to a node module so that we can upgrade
 * all sites if necessary. This file is a shim between the old component and the
 * new module. In the future, all components on new sites will be node modules
 * and this file will no longer be needed.
 */

import { Polyfills } from 'components-yext-analytics/@yext/components-polyfills/Polyfills.js';
import { Instance as _Instance } from 'components-yext-analytics/@yext/components-yext-analytics/Analytics.js';

Polyfills.init();

// Older sites that try to do "new Analytics(window)" will mess up because
// Instance has read and removed the creation data from window. This replaces
// the Analytics class with a dummy constructor that returns Instance.
class Analytics {
  constructor(win, eventNameCalculator = _Instance._eventNameCalculator) {
    _Instance._eventNameCalculator = eventNameCalculator;
    return _Instance;
  }

  // Hax to stop the TypeScript compiler from throwing an error
  // Without this, it thinks CalcEventNameForElement is not a property.
  CalcEventNameForElement(target) {
    return _Instance.CalcEventNameForElement(target);
  }
}

const Instance = new Analytics();

export {
  Analytics as default,
  Analytics,
  Instance
};
