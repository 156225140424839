import { Throttle } from 'js/components/Util/Throttle.js';

class OptimizedResize {
  constructor(scope = window) {
    this.eventTypeName = 'optimizedResize';
    this.throttle = new Throttle('resize', this.eventTypeName, scope);
  }

  on(cb) {
    this.throttle.start();
    window.addEventListener(this.eventTypeName, cb);
  }

  remove(cb) {
    window.removeEventListener(this.eventTypeName, cb);
    this.throttle.end();
  }
}

export const OptimizedResizeInstance = new OptimizedResize();
