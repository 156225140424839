import { missing, checktype } from './utils.js';
import { Funcs } from './funcs.js';
import { stateAbbrReverseMap } from './stateAbbrMap.js';

export const other = () =>
  // Implement other
  missing("other");
;

export const encodeBase64 = function (str) {
  checktype(str, 'string');
  const btoa = (str) => window ? window.btoa(str): Buffer.from(str).toString('base64');
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      (match, p1) => String.fromCharCode('0x' + p1)
    )
  );
};

export const abbr = (abbr, useAbbrev) => {
  if (useAbbrev == null) { useAbbrev = false; }
  checktype(abbr, 'string');
  if (useAbbrev) {
    return abbr;
  }

  let lookup = stateAbbrReverseMap[abbr];
  if (lookup) { return lookup; } else { return abbr; }
};

export const slugify = (string) => {
  checktype(string, 'string');
  if ((typeof s !== 'undefined' && s !== null) && (typeof s.slugify === 'function')) {
    return s.slugify(string);
  } else if ((typeof _ !== 'undefined' && _ !== null) && (typeof _.slugify === 'function')) {
    return _.slugify(string);
  } else if ((typeof underscore !== 'undefined' && underscore !== null) && typeof underscore.slugify === 'function') {
    return underscore.slugify(string);
  } else if (typeof window.slugify === 'function') {
      return window.slugify(string);
  } else { throw new Error("slugify not included (slugify or underscore.string.js)"); }
};

export const rreplace = (string, search, replace) => {
  if (replace == null) { replace = ''; }
  return Funcs.replace(string, search, replace);
};

export const encodeUrl = (string) => {
  checktype(string, 'string');
  return encodeURI(string);
};

export const casing = (string, option) => {
  checktype(string, 'string');
  checktype(option, 'string');

  switch (option) {
    case 'lower': return string.toLowerCase();
    case 'upper': return string.toUpperCase();
    case 'retain': return string;
    default: throw new Error(`unsupported case argument: ${option}`);
  }
};

export const spaces = (string, replace) => {
  checktype(string, 'string');
  return string.replace(/\s/g, replace);
};

export const title = (string) => {
  checktype(string, 'string');
  return string.replace(
    /\b[a-z]/g,
    letter => casing(letter, 'upper')
  );
};

export const lower = (string) => {
  checktype(string, 'string');
  return casing(string, 'lower');
};

export const stripnum = (address, enable) => {
  checktype(address, 'string');
  if (!enable) {
    return address;
  }
  for (const ch of address) {
    if ('0' <= ch && ch <= '9') {
      continue;
    }
    if (ch == ' ') {
      return address.substring(address.indexOf(ch) + 1);
    }
    break;
  }
  return address;
};

export const fullCountry = (string, enable) => {
  missing('i18n :/');
};

export const fullRegion = (string, enable) => {
  missing('i18n :/');
};

export const latin = (string, enable) => {
  missing('i18n :/');
};

// stateAbbr is an alias of abbr in Go
export const stateAbbr = abbr;

let Directives = {};
Directives.other = other;
Directives.encodeBase64 = encodeBase64
Directives.abbr = abbr
Directives.slugify = slugify
Directives.rreplace = rreplace
Directives.encodeUrl = encodeUrl
Directives.casing = casing
Directives.spaces = spaces;
Directives.title = title;
Directives.lower = lower;
Directives.stripnum = stripnum;
Directives.fullCountry =  fullCountry;
Directives.fullRegion =  fullRegion;
Directives.latin = latin;
Directives.stateAbbr =  stateAbbr;

export {
  Directives
}
